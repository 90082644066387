@import '../layout/color.scss';

.expand-card {
  margin-bottom: 4px;
  &.MuiExpansionPanel-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiExpansionPanelSummary-root {
    padding: 0px;
    min-height: unset;
    &.Mui-expanded {
      min-height: unset;
    }
    .MuiExpansionPanelSummary-content {
      margin: 0px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 14px;
      // color: #212121;
    }
  }
  .MuiExpansionPanelDetails-root {
    padding: 8px 12px 0px;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
}
// .expand-card {
//   margin-bottom: 4px;
//   &.MuiAccordion-root {
//     border-radius: 3px;
//     box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
//       0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
//     &::before {
//       background: none;
//     }
//     &.Mui-expanded {
//       margin: 0px 0px 4px;
//     }
//   }
//   .MuiAccordionSummary-root {
//     padding: 0px;
//     min-height: unset;
//     &.Mui-expanded {
//       min-height: unset;
//     }
//     .MuiAccordionSummary-content {
//       margin: 0px;
//       .core-list {
//         padding-bottom: 0px;
//         width: 100%;
//         .MuiListItem-root {
//           box-shadow: unset;
//           background: unset;
//           margin: unset;
//         }
//       }
//     }
//     .MuiButtonBase-root {
//       padding: 0px;
//       margin-right: 14px;
//       // color: #212121;
//     }
//   }
//   .MuiAccordionDetails-root {
//     padding: 8px 12px 0px;
//     flex-wrap: wrap;
//     border-top: 1px solid #e6e6e6;
//   }
// }

.card-form {
  margin-bottom: 4px;
  border-radius: 3px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  .MuiPaper-root {
    box-shadow: unset;
  }
  .MuiCardHeader-root {
    padding: 10px;
    background-color: #e5edff;
    color: $color-dark-blue;
    .MuiCardHeader-action {
      display: flex;
      margin: 0px;
      .MuiIconButton-root {
        padding: 0px;
        .MuiIconButton-label {
          color: $color-dark-blue;
        }
      }
    }
  }
  .MuiCardContent-root {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 12px;

    .left {
      &.MuiFormControl-root,
      &.MuiAutocomplete-root {
        width: 50%;
        padding-right: 6px;
      }
    }
    .right {
      &.MuiFormControl-root,
      &.MuiAutocomplete-root {
        width: 50%;
        padding-left: 6px;
        .MuiInputLabel-formControl {
          left: 6px;
        }
      }
    }
    &:last-child {
      padding-bottom: 0px;
    }
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 12px;
      box-sizing: border-box;
    }
  }
  .btn-container {
    text-align: right;
    padding-top: 10px;
    margin-bottom: 10px;
    button {
      margin-left: 10px;
      text-transform: capitalize;
    }
  }
}

.card-function {
  padding-bottom: 8px;
  .MuiGrid-item {
    overflow: hidden;
    padding-bottom: 8px;
  }
  .MuiPaper-root {
    cursor: pointer;
    text-align: center;
    padding: 12px 3px;
    background: #e5edff;
    .MuiListItemAvatar-root {
      min-width: unset;
    }
    .MuiAvatar-root {
      margin: 0 auto;
      background: unset;
      height: auto;
      margin-bottom: 4px;
    }
    .MuiBadge-badge {
      box-shadow: 0 0 0 2px #e5edff;
      background-color: #ff1818;
      color: white;
      margin-left: -16px;
      right: 4px;
      top: 2px;
    }
    .MuiBadge-badge1 {
      box-shadow: 0 0 0 2px #e5edff;
      background-color: #4e18ff;
      color: white;
      margin-left: -16px;
      right: 4px;
      top: 2px;
    }
    img {
      width: 20px;
    }
    div {
      line-height: 12px;
    }
  }
  .mobile-app-grid {
    // padding: 0px 16px;
    margin-top: 5px !important;
  }
  .MuiGrid-grid-xs-4 {
    padding: 10px;
  }
  .mobile-app {
    img {
      width: 80px;
    }
  }
  .MuiPaper-root {
    background: transparent;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .mobile-app-label {
    text-align: center;
    // margin-top: 8px;
  }
}
.card-function2 {
  padding-bottom: 8px;
  .MuiGrid-item {
    overflow: hidden;
    padding-bottom: 8px;
  }
  .MuiPaper-root {
    cursor: pointer;
    text-align: center;
    padding: 12px 3px;
    background: #e5edff;
    .MuiListItemAvatar-root {
      min-width: unset;
    }
    .MuiAvatar-root {
      margin: 0 auto;
      background: unset;
      height: auto;
      margin-bottom: 4px;
    }
    .MuiBadge-badge {
      box-shadow: 0 0 0 2px #e5edff;
      background-color: #4e18ff;
      color: white;
      margin-left: -16px;
      right: 4px;
      top: 2px;
    }

    img {
      width: 20px;
    }
    div {
      line-height: 12px;
    }
  }
  .mobile-app-grid {
    // padding: 0px 16px;
    margin-top: 5px !important;
  }
  .MuiGrid-grid-xs-4 {
    padding: 10px;
  }
  .mobile-app {
    img {
      width: 80px;
    }
  }
  .MuiPaper-root {
    background: transparent;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .mobile-app-label {
    text-align: center;
    // margin-top: 8px;
  }
}

.content-wrap {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 12px;
  &.full {
    width: 100%;
  }
}

.content-container {
  padding-top: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;
  &.checkbox {
    padding-left: 34px;
  }
  .MuiFormControl-root {
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
  }
  .left.MuiFormControl-root {
    width: 50%;
    padding-right: 6px;
  }
  .right.MuiFormControl-root {
    width: 50%;
    padding-left: 6px;
    .MuiInputLabel-formControl {
      left: 6px;
    }
  }
}

.card-header-btn span svg.MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  color: #0019d8;
  margin-top: 5px;
}

button.MuiButtonBase-root.MuiIconButton-root.card-header-btn {
  margin-right: 9px;
}
